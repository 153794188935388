<script setup>
    import { onMounted, reactive, ref, watch } from 'vue'
    import { useRoute } from 'vue-router'
    import { idTypeList,
            getDictList,
            politicaltList,
            getPoliticaltList,
            personCateList,
            getPersonCateList,
            maritalStatus,
            getMaritalStatusList,
            entreRelationList,
            getEntreRelationList,
            entrepreneurList,
            getEntrepreneurList,
            guaranWayList,
            getGuaranWayList,
            bankList,
            getBankList,
            guarantorsList,
            getBankList1,
            getGuarantorsList,
            getGuarantorsList1,
            loanEntrepreneurTypeList,
            getEntrepTypeList,
            deptList,
            getDeptList
    } from '@/api/certificateList.js'
    import { getIdentityDuplicationInThreee } from '@/api/index.js'
    import { getEntrepreneurDoorDtoPerson } from '@/api/applyList.js'
    import { ElMessage } from 'element-plus';
    const props = defineProps(['amountRaiseStatus']);
    const route = useRoute();
    const showModal = ref(false);
    const dialogFullScreen = ref(false);
    const documentClassS = ref("");
    const reviewTitle = ref("所需证明材料");

    const checkLoanAmount = (rule, value, callback) => {
      if (value > 30 && props.amountRaiseStatus == '0') {
        callback(new Error("申请金额不能大于30万"));
      } else if (value > 33 && props.amountRaiseStatus == '1') {
        callback(new Error("申请金额不能大于33万"));
      } else {
        callback();
      }
    };

    // 贷款期限
    const checkLoanTerm = (rule, value, callback) => {
        let num = Number(value)
        if ( num > 36 || num < 1) {
            callback(new Error("贷款期限在1-36个月之间"));
        } else {
            callback();
        }
    };
    // 类型提示消息
    const documentClass = (rule, value, callback) => {
      // 开启弹窗
      showModal.value = true;

      if(formLabelAlign.loanPersonCategory === "01"){
        documentClassS.value = "";
        showModal.value = false;
      }
      else if(formLabelAlign.loanPersonCategory === "02"){
        documentClassS.value = "需要在第四步提供化解过剩产能企业工作证明（包括社保缴费记录、劳动合同、解除劳动合同通知\n" +
            "书等），或化解过剩产能企业出具证明";
        showModal.value = false;
      }
      else if(formLabelAlign.loanPersonCategory === "03"){
        documentClassS.value = "需要在第四步提供就业困难人员（含残疾人）相关证明材料：通过“湖北智慧就业服务\n" +
            "平台”查验就业困难人员登记信息（残疾人提交残疾证）";
      }
      else if(formLabelAlign.loanPersonCategory === "04"){
        documentClassS.value = "需要在第四步提供复员、转业、退役等证件(提供其中一项即可)";

      }
      else if(formLabelAlign.loanPersonCategory === "05"){
        documentClassS.value = "需要在第四步提供司法部门出具的刑满释放相关材料";
      }
      else if(formLabelAlign.loanPersonCategory === "06"){
        documentClassS.value = "需要在第四步提供普通高等院校专科（高职高专）及以上学历毕业证书或者（境）外学历学位认证书（以上证书可由学信网学历证明代替）";
      }
      else if(formLabelAlign.loanPersonCategory === "07"){
        //脱贫人口
        documentClassS.value = "";
        showModal.value = false;
      }
      else if(formLabelAlign.loanPersonCategory === "08"){
        documentClassS.value = "需要在第四步提供营业执照材料,在第三方平台（网络）实名注册信息或自主创办的平台（网站）注册信息，以及平台（网站）近 3 个月交易记录";
      }
      else if(formLabelAlign.loanPersonCategory === "09"){
        //返乡创业农民工
        documentClassS.value = "需要在第四步提供户口簿，或土地承包经营权证等，外\n" +
            "地就业、创业、就学、服兵役等佐证材料（不论户口是否迁出，\n" +
            "原籍在本县（市/区）行政区域范围的人员回到本县（市/区）等佐证材料";
      }
      else if(formLabelAlign.loanPersonCategory === "10"){
        //农村自主创业农民
        documentClassS.value = "需要在第四步提供户口簿，或土地承包经营权证";
      }else {
        callback();
      }
    };


    function approve() {
      // 关闭弹窗
      showModal.value = false;
      showQrcode.value = false;
      formLabelAlign.operatingAddress = [];
    }
    function reject() {
      // 关闭弹窗
      showModal.value = false;
    }

    const formLabelAlign = reactive({
        name: null,
        idType: null,
        sex: null,
        politicalOutlook:'',
        residenceAddress:[],
        permanentAddress:[],
        loanPersonCategory:null,
        maritalStatus:null,
        spouseSex:null,
        spouseIdNumber:null,
        emergencyContact:null,
        entrepreneurRelation:null,
        idNumber:null,
        phone:null,
        firstEntrepreneurFlag:null,
        spouseName:null,
        spouseIdType:null,
        withSpouseIncomplete:null,
        emergencyContactPhone:null,
        entrepreneurCategory:null,
        unitName:null,
        socialCode:null,
        recommendUnit:null,
        guaranteeWay:null,
        operatingAddress:[],
        businessScope:null,
        loanAmount:null,
        bankId:null,
        loanTerm:null,
        guaranteeOrganizationName:null,
        counterGuarantee:null,
        loanEntrepreneurType:null,
        loanPurpose:null,
        permanentAddressDetails:null,
        residenceAddressDetails:null,
        operatingAddressDetails:null,
        regionCode:'420106',
        // agencyNumber:'1001',
        // agencyName:'荆州人社',
        operatingAreaCode:'420103',
    })

    const rules = reactive({
        name: [
            { required: true, message: '请输入姓名', trigger: 'blur'},
            { pattern: /^.{1,150}$/, message: "姓名长度必须在1到150之间" }
        ],
        idType: [
            {required: true,message: '请选择证件类型',trigger: 'change'},

        ],
        sex: [
            { required: true, message: '请选择性别', trigger: 'change'  },

        ],
        politicalOutlook: [
            {required: true,message: '请选择政治面貌',trigger: 'change'},

        ],
        residenceAddress:[
            {required: true,message: '请选择户籍地址',trigger: 'change'},
            { pattern: /^.{0,255}$/, message: "户籍地址长度不能超过255个字符" }
        ],
        residenceAddressDetails:[
          { pattern: /^.{0,255}$/, message: "详细户籍地址长度不能超过255个字符" }
        ],
        permanentAddress:[
            {required: true,message: '请选择常住地址',trigger: 'change'},
            { pattern: /^.{0,255}$/, message: "常住地址长度不能超过255个字符" }
        ],
        permanentAddressDetails:[
          { pattern: /^.{0,255}$/, message: "详细户籍地址长度不能超过255个字符" }
        ],
        loanPersonCategory:[
            {required: true,message: '请选择创业担保贷款人员类别',trigger: 'change'},

        ],
        maritalStatus:[
            {required: true,message: '请选择婚姻状况',trigger: 'change'}
        ],
        spouseSex:[
            {required: true, message: '请选择配偶性别', trigger: 'change'  }
        ],
        spouseIdNumber: [
            {required: false,message: '请输入证件号码',trigger: 'change'},
            { 
                pattern: /^([a-zA-Z0-9-]{1,18})$/, 
                message: '请输入1-18位的数字和字母，支持中划线', 
                trigger: 'blur' 
            },
        ],
        emergencyContact:[
            {required: true,message: '请输入紧急联系人',trigger: 'change'},
            { pattern: /^.{0,255}$/, message: "紧急联系人长度不能超过255个字符" }
        ],
        entrepreneurRelation:[
            {required: true,message: '请选择与借款人关系',trigger: 'change'}
        ],
        idNumber:[
            {required: true,message: '请输入证件号码',trigger: 'change'},
            { 
                pattern: /^([a-zA-Z0-9-]{1,18})$/, 
                message: '请输入1-18位的数字和字母，支持中划线', 
                trigger: 'blur' 
            },
        ],
        phone:[
            {required: true,message: '请输入联系电话',trigger: 'change'},
            {
                pattern: /^((0\d{2,3}(-)?\d{7,8})|(13[0-9]|14[01456879]|15[0-35-9]|16[2567]|17[0-8]|18[0-9]|19[0-35-9])\d{8})$/, 
                message: '手机号码格式不正确', 
                trigger: ['change', 'blur'] 
            },
        ],
        firstEntrepreneurFlag:[
            {required: true, message: '请选择是否首次创业', trigger: 'change'  }
        ],
        spouseName:[
            {required: true,message: '请输入配偶姓名',trigger: 'change'}
        ],
        spouseIdType: [
            {required: true,message: '请选择证件类型',trigger: 'change'}
        ],
        emergencyContactPhone:[
            {required: true,message: '请输入紧急联系人电话',trigger: 'change'},
            {
                pattern: /^((0\d{2,3}(-)?\d{7,8})|(13[0-9]|14[01456879]|15[0-35-9]|16[2567]|17[0-8]|18[0-9]|19[0-35-9])\d{8})$/, 
                message: '手机号码格式不正确', 
                trigger: ['change', 'blur'] 
            },
        ],
        entrepreneurCategory:[ {required: true,message: '请选择',trigger: 'change'}],
        unitName:[{required: true,message: '请输入营业执照单位全称',trigger: 'change'}],
        socialCode:[
            {required: true,message: '请输入统一社会信用代码',trigger: 'change'},
            { 
                pattern: /^([a-zA-Z0-9]{18})$/, 
                message: '长度只能是18位的数字和字母', 
                trigger: 'change' 
            },
        ],
        guaranteeWay:[
            {required: false,message: '请选择担保增信方式',trigger: 'change'}
        ],
        operatingAddress:[
            {required: true,message: '请选择经营所在地地址',trigger: 'change'}
        ],
        operatingAddressDetails:[
          //{required: true,message: '请选择经营所在地地址',trigger: 'change'}
          { pattern: /^.{1,255}$/, message: "详细经营所在地地址字符数量必须在1到255之间" }
        ],
        recommendUnit:[
          { pattern: /^[\u4e00-\u9fa5a-zA-Z0-9_]+$/, message: "请输入正确的推荐单位名称，只允许包含中文、英文、数字和下划线" }
        ],
        businessScope:[
            {required: true,message: '请输入经营范围',trigger: 'change'},
            { pattern: /^.{1,1000}$/, message: "经营范围格式不正确", trigger: "blur" }
        ],
        loanAmount:[
            {required: true,message: '请输入申请金额',trigger: 'change'},
            { pattern: /^\d{1,5}(\.\d{1,2})?$/, message: "申请金额格式不正确", trigger: "change" },
            { validator:checkLoanAmount, trigger: "change" }
        ],
        bankId:[
            {required: true,message: '请选择贷款银行',trigger: 'change'}
        ],
        loanTerm:[
            {required: true,message: '请输入贷款期限',trigger: 'change'},
            { pattern: /^\d{1,2}$/, message: "贷款期限必须是1到2位数字" },
            {validator:checkLoanTerm, trigger: "change" }
        ],
        guaranteeOrganizationName:[
            {required: false,message: '请选择担保机构',trigger: 'change'}
        ],
        loanEntrepreneurType:[
            {required: true,message: '请选择贷款创业类型',trigger: 'change'}
        ],
        loanPurpose: [
          { pattern: /^.{1,1000}$/, message: "贷款用途格式不正确", trigger: "blur" }
        ],
    })

    const showQrcode = ref(false);
    const qrCodeUrl = ref([]);
    import QRCODE_YC from '@/assets/YC.jpg';
    import QRCODE_XG from '@/assets/XG.jpg';
    import QRCODE_XY_1 from '@/assets/XY_1.jpg';
    import QRCODE_XY_2 from '@/assets/XY_2.jpg';
    import QRCODE_XT from '@/assets/XT.jpg';
    import QRCODE_SLJ from '@/assets/SLJ.jpg';
    import QRCODE_SZ from '@/assets/SZ.jpg';
    const getResidenceAddress = (e) => {
        if (e[1] === 111 || e[1] === 112 || e[1] === 115 || e[1] === 119 || e[2] === 121 || e[2] === 124) {
            if (e[1] === 111) {
                qrCodeUrl.value = [{ img: QRCODE_YC, type: 'wx' }];
            } else if (e[1] === 115) {
                qrCodeUrl.value = [{ img: QRCODE_XG, type: 'wx' }];
            } else if (e[1] === 112) {
                qrCodeUrl.value = [{ img: QRCODE_XY_1, type: 'wx' }, { img: QRCODE_XY_2, type: 'app' }]
            } else if (e[1] === 119) {
                qrCodeUrl.value = [{ img: QRCODE_SZ, type: 'wx' }];
            } else if (e[2] === 121) {
                qrCodeUrl.value = [{ img: QRCODE_XT, type: 'wx' }];
            } else if (e[2] === 124) {
                qrCodeUrl.value = [{ img: QRCODE_SLJ, type: 'wx' }];
            };
            formLabelAlign.operatingAddress = [];
            showQrcode.value = true;
            return;
        };
        getBankList1(e[2]);
        getGuarantorsList1(e[2]);
        formLabelAlign.bankId = null;
        formLabelAlign.guaranteeOrganizationName = null;
    }

    const loading = ref(false)
    const ruleFormRef = ref();
    const validPass = ref(false)
    const submitTemporaryStorage = () =>{
        loading.value = true
        ruleFormRef.value.validate((valid) =>{
           return true
        })
    }

    // 如果是未婚 取消验证配偶姓名
    const hideSopseName = ref(true)
    const changeSposeName = (e) =>{
        if (e != 1) {
            hideSopseName.value = false;
            formLabelAlign.spouseSex = null;
        } else {
            hideSopseName.value = true;
            if (formLabelAlign.sex === '0') {
                formLabelAlign.spouseSex = '1'
            } else if (formLabelAlign.sex === '1') {
                formLabelAlign.spouseSex = '0';
            };
        };
    };

    // 检测身份证已经在3年内注册过了
    const checkoutIdInThY = async(e) =>{
        if(e.target.value){
            await getIdentityDuplicationInThreee(e.target.value).then((res) =>{
                if(res.code != 200){
                    ElMessage.error('此身份证号码已经在3年内申请过，请勿重复申请');
                    return false;
                };
            });
        };
    };

    defineExpose({
        formLabelAlign,
        validPass,
        submitTemporaryStorage,
        ruleFormRef,
    });

    onMounted(() =>{
        let id = route.query.id;
        if (id) {
            getEntrepreneurDoorDtoPerson(id).then(res => {
                let residenceAddress = res.data.operatingAddress;
                if (residenceAddress) {
                    residenceAddress = residenceAddress.split(',');
                    getResidenceAddress(residenceAddress);
                }
            })
        }
        getDictList()
        getPoliticaltList()
        getPersonCateList()
        getMaritalStatusList()
        getEntreRelationList()
        getEntrepreneurList()
        getGuaranWayList()
        // getBankList()
        // getGuarantorsList()
        getDeptList()
        getEntrepTypeList()
    })
</script>

<template>
    <div class="box-right" :v-loading="loading">

        <!-- 宜昌和孝感地区二维码 -->
        <el-dialog title="提示" v-model="showQrcode" width="800px" append-to-body :fullscreen="dialogFullScreen"
                    :class="[dialogFullScreen ? 'fullscreen' : 'no_fullscreen']">
            <div class="qrCode">
                <div class="qrCode-item" v-for="(item, index) in qrCodeUrl" :key="index">
                    <el-image style="width: 200px; height: 200px" :src="item.img" fit="fill"></el-image>
                    <div class="tips">请用{{ item.type === 'wx' ? '微信' : '鄂汇办' }}扫码，并在手机上完成申请</div>
                </div>
            </div>
            <template #footer>
            <div class="dialog-footer">
                <el-button @click="approve">确定</el-button>
            </div>
            </template>
        </el-dialog>

        <div class="popup_edit" v-if="showModal" @mousewheel.stop.prevent="">
            <div class="mask" @click="showModal = false"></div>
            <div class="content">
                <h3 class="title">{{ reviewTitle }}</h3>
                <div class="list">{{ documentClassS }}</div>
                <div class="popup-btns">
                    <el-button type="warning" size="large" @click="showModal = false">确认</el-button>
                </div>
            </div>
        </div>

        <h2><span></span>创业人员基本信息</h2>
        <el-form
            label-position="top"
            label-width="100px"
            ref="ruleFormRef"
            :rules="rules"
            :model="formLabelAlign">

            <el-row :gutter="24">
                <el-col :span="12">
                    <el-form-item label="姓名" prop="name">
                        <el-input v-model="formLabelAlign.name" placeholder="请输入姓名"/>
                    </el-form-item>
                    <el-form-item label="证件类型" prop="idType">
                        <el-select v-model="formLabelAlign.idType" placeholder="请选择证件类型">
                            <el-option  v-for="(item, index) in idTypeList" :label="item.dictLabel" :value="item.dictValue" :key="index"/>
                        </el-select>
                    </el-form-item>
                    <el-form-item label="政治面貌">
                        <el-select v-model="formLabelAlign.politicalOutlook" placeholder="请选择政治面貌">
                            <el-option v-for="(item, index) in politicaltList" :label="item.dictLabel" :value="item.dictValue" :key="index"/>
                        </el-select>
                    </el-form-item>

                    <el-form-item label="户籍地址" prop="residenceAddress">
                        <el-cascader  
                            v-model="formLabelAlign.residenceAddress"  
                            :options="deptList"  
                            placeholder="请选择户籍地址"
                            :props="{ value: 'id',label: 'label',children: 'children'}"
                            style="width: 100%;"
                        />
                    </el-form-item>
                    <el-form-item label="常住地址" prop="permanentAddress">
                        <el-cascader   v-model="formLabelAlign.permanentAddress" :options="deptList"  placeholder="请选择常住地址"    
                            :props="{ value: 'id',label: 'label',children: 'children'}"
                            style="width: 100%;"/>
                    </el-form-item>
                    <el-form-item label="创业担保贷款人员类别" prop="loanPersonCategory">
                        <el-select v-model="formLabelAlign.loanPersonCategory" placeholder="请选择创业担保贷款人员类别" @change="documentClass">
                            <el-option  v-for="(item, index) in personCateList" :label="item.dictLabel" :value="item.dictValue" :key="index"/>
                        </el-select>
                    </el-form-item>
                    <el-form-item label="婚姻状况" prop="maritalStatus">
                        <el-select v-model="formLabelAlign.maritalStatus" placeholder="请选择婚姻状况"  @change="changeSposeName">
                            <el-option v-for="(item, index) in maritalStatus" :label="item.dictLabel" :value="item.dictValue" :key="index" />
                        </el-select>
                    </el-form-item>
                    <el-form-item label="配偶性别">
                        <el-select v-model="formLabelAlign.spouseSex" placeholder="请选择性别" disabled>
                            <el-option label="男" value="0" />
                            <el-option label="女" value="1" />
                        </el-select>
                    </el-form-item>
                    <el-form-item label="证件号码" prop="spouseIdNumber">
                        <el-input v-model="formLabelAlign.spouseIdNumber" placeholder="请输入证件号码" :disabled="formLabelAlign.maritalStatus != 1"/>
                    </el-form-item>
                    <el-form-item label="紧急联系人" prop="emergencyContact">
                        <el-input v-model="formLabelAlign.emergencyContact" placeholder="请输入紧急联系人"/>
                    </el-form-item>
                    <el-form-item label="与借款人关系" prop="entrepreneurRelation">
                        <el-select v-model="formLabelAlign.entrepreneurRelation" placeholder="请选择与借款人关系">
                            <el-option v-for="(item, index) in entreRelationList" :label="item.dictLabel" :value="item.dictValue" :key="index" />
                        </el-select>
                    </el-form-item>
                </el-col>

                <el-col :span="12">
                    <el-form-item label="性别" prop="sex">
                        <el-select v-model="formLabelAlign.sex"  placeholder="请选择性别">
                            <el-option label="男" value="0" />
                            <el-option label="女" value="1" />
                        </el-select>
                    </el-form-item>
                    <el-form-item label="证件号码" prop="idNumber">
                        <el-input v-model="formLabelAlign.idNumber"  placeholder="请输入证件号码" @blur="checkoutIdInThY"/>
                    </el-form-item>
                    <el-form-item label="联系电话" prop="phone">
                        <el-input v-model="formLabelAlign.phone"  placeholder="请输入联系电话"/>
                    </el-form-item>
                    <el-form-item label="    ">
                        <div style="width: 100%; line-height: 5.5rem; height: 3.8rem;">
                            <el-input placeholder="请输入详细户籍地址" v-model="formLabelAlign.residenceAddressDetails"/>
                        </div>
                    </el-form-item>
                    <el-form-item label="    ">
                        <div style="width: 100%; line-height: 5.5rem; height: 3.8rem;">
                            <el-input placeholder="请输入详细常住地址" v-model="formLabelAlign.permanentAddressDetails"/>
                        </div>
                    </el-form-item>

                    <el-form-item label="是否首次创业" prop="firstEntrepreneurFlag">
                        <el-select v-model="formLabelAlign.firstEntrepreneurFlag" placeholder="请选择是否首次创业">
                            <el-option label="是" value="1" />
                            <el-option label="否" value="0" />
                        </el-select>
                    </el-form-item>
                    <el-form-item label="配偶姓名">
                        <el-input v-model="formLabelAlign.spouseName" placeholder="请输入配偶姓名" :disabled="formLabelAlign.maritalStatus != 1"/>
                    </el-form-item>
                    <el-form-item label="证件类型">
                        <el-select v-model="formLabelAlign.spouseIdType" placeholder="请选择证件类型" :disabled="formLabelAlign.maritalStatus != 1">
                            <el-option v-for="(item, index) in idTypeList" :label="item.dictLabel" :value="item.dictValue" :key="index"/>
                        </el-select>
                    </el-form-item>
                    <el-form-item label="申请人及配偶当前已获得的贷款项目和未尝还余额">
                        <el-input v-model="formLabelAlign.withSpouseIncomplete" placeholder="请输入" :disabled="formLabelAlign.maritalStatus != 1"/>
                    </el-form-item>
                    <el-form-item label="紧急联系人电话" prop="emergencyContactPhone">
                        <el-input v-model="formLabelAlign.emergencyContactPhone" placeholder="请输入紧急联系人电话"/>
                    </el-form-item>
                </el-col>
            </el-row>

            <el-row :gutter="24">
                <el-col :span="12">
                    <el-form-item label="创业扶持人员类别" prop="entrepreneurCategory">
                        <el-select v-model="formLabelAlign.entrepreneurCategory" placeholder="请选择">
                            <el-option v-for="(item, index) in  entrepreneurList " :label="item.dictLabel" :value="item.dictValue" :key="index"/>
                        </el-select>
                    </el-form-item>
                </el-col>

                <el-col :span="12">
                    <el-form-item label="营业执照单位全称" prop="unitName">
                        <el-input v-model="formLabelAlign.unitName" placeholder="请输入营业执照全称"/>
                    </el-form-item>
                </el-col>
            </el-row>

            <el-row :gutter="24">
                <el-col :span="12">
                    <el-form-item label="统一社会信用代码" prop="socialCode">
                        <el-input v-model="formLabelAlign.socialCode" placeholder="请输入统一社会信用代码"/>
                    </el-form-item>
                </el-col>
            </el-row>

            <el-row  :gutter="24">
                <el-col :span="12">
                    <el-form-item label="经营地址" prop="operatingAddress">
                        <el-cascader   v-model="formLabelAlign.operatingAddress" :options="deptList"  placeholder="请选择常住地址"    
                            :props="{ value: 'id',label: 'label',children: 'children'}"
                            style="width: 100%;" @change="getResidenceAddress"/>
                    </el-form-item>
                </el-col>

                <el-col :span="12">
                    <el-form-item label="" >
                        <div style=" width: 100%;line-height: 5.5rem; height: 3.8rem;">
                            <el-input  placeholder="请输入详细经营地址" v-model="formLabelAlign.operatingAddressDetails"/>
                        </div>
                    </el-form-item>
                </el-col>
            </el-row>

            <el-row :gutter="24">
                <el-col :span="12">
                    <el-form-item label="推荐单位名称">
                        <el-input v-model="formLabelAlign.recommendUnit" placeholder="请输入推荐单位名称"/>
                    </el-form-item>
                </el-col>
            </el-row>

            <el-row :gutter="24">
                <el-col :span="24">
                    <el-form-item label="经营范围及项目" prop="businessScope">
                        <el-input v-model="formLabelAlign.businessScope" type="textarea"  placeholder="请输入经营范围"/>
                    </el-form-item>
                </el-col>
            </el-row>

            <el-row :gutter="24">
                <el-col :span="12">
                    <el-form-item label="申请贷款金额" prop="loanAmount" placeholder="请输入申请金额">
                        <el-input v-model="formLabelAlign.loanAmount" >
                            <template #append>万元</template>
                        </el-input>
                    </el-form-item>
                    <el-form-item label="担保增信方式" prop="guaranteeWay">
                        <el-select v-model="formLabelAlign.guaranteeWay" placeholder="请选择担保增信方式">
                            <el-option v-for="(item, index) in guaranWayList" :label="item.dictLabel" :value="item.dictValue" :key="index" />
                        </el-select>
                    </el-form-item>
                    <el-form-item label="贷款银行" prop="bankId">
                        <el-select v-model="formLabelAlign.bankId" placeholder="请选择贷款银行">
                            <el-option  v-for="(item, index) in bankList" :value="index" :label="item" :key="index"/>
                        </el-select>
                    </el-form-item>
                </el-col>
                <el-col :span="12">
                    <el-form-item label="贷款期限" prop="loanTerm">
                        <el-input v-model="formLabelAlign.loanTerm" placeholder="请输入贷款期限">
                            <template #append>月</template>
                        </el-input>
                    </el-form-item>
                    <el-form-item label="担保机构" prop="guaranteeOrganizationName">
                        <el-select v-model="formLabelAlign.guaranteeOrganizationName" placeholder="请选择担保机构">
                            <el-option  v-for="(item, index) in guarantorsList" :value="index" :label="item" :key="index"/>
                        </el-select>
                    </el-form-item>
                    <el-form-item label="是否免除反担保">
                        <el-select v-model="formLabelAlign.counterGuarantee" placeholder="请选择">
                            <el-option label="是" value="0" />
                            <el-option label="否" value="1" />
                        </el-select>
                    </el-form-item>
                </el-col>
            </el-row>
            
            <el-row :gutter="24">
                <el-col :span="24">
                    <el-form-item label="贷款创业类型" prop="loanEntrepreneurType">
                        <el-select v-model="formLabelAlign.loanEntrepreneurType" placeholder="请选择贷款创业类型">
                            <el-option v-for="(item, index) in  loanEntrepreneurTypeList" :label="item.dictLabel" :value="item.dictValue" :key="index"/>
                        </el-select>
                    </el-form-item>
                </el-col>
                <el-col :span="24">
                    <el-form-item label="申请贷款用途">
                        <el-input v-model="formLabelAlign.loanPurpose" placeholder="请输入申请贷款用途"  type="textarea"/>
                    </el-form-item>
                </el-col>
            </el-row>
        </el-form>
    </div>


</template>

<style scoped>
.box-right{
    background-color: #ffffff;
    padding: 1rem 1rem 2rem;
    width: 80%;
}
</style>

<style lang="scss" scoped>
.el-input-group__append, .el-input-group__prepend{
    background-color: #ffffff !important;
    margin-left: -5px;
}

.qrCode {
    display: flex;
    justify-content: center;
    align-items: center;
    .qrCode-item {
        margin-right: 40px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        .tips {
            margin-top: 20px;
        }
    }
    .qrCode-item:last-child {
        margin-right: 0;
    }
}

.centered-div {
  display: flex;
  justify-content: center;
  align-items: center;
}


.popup_edit {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);
  width: 100%;
  height: 100%;
  animation: show 0.2s linear;
  z-index: 9999;
  .mask {
    background-color: rgba(0, 0, 0, 0.3);
    width: 100%;
    height: 100%;
  }
  .content {
    padding: 0 1rem 1rem 2rem;
    position: absolute;
    width: 33%;
    max-height: 30rem;
    background-color: #fff;
    top: 50%;
    left: 50%;
    transform: translateX(-50%) translateY(-50%);
    border-radius: 12px;
    h3 {
      text-align: center;
    }
    .list {
        text-align: center;
    }
    .items {
      display: flex;
      align-items: center;
    }
    .popup-btns {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-top: 1rem;
      :deep(.el-button) {
        width: 8rem;
      }
    }
  }
}

@keyframes show {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}
</style>